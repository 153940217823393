// Activities.js
import React from 'react';
import ImageGallery from '../images/ImageGallery'; // Import ImageGallery component

function Activities() {
  const images = [
    require('./Theqdarts.jpg'),
    require('./theqhatchetlanes.JPG'),
    require('./theqhall.JPG'),
    require('./poolcuewall.JPG'),
    require('./touchtunes.JPG'),
    require('./tvdisplay.JPG'),
    require('./vending.JPG'),
    require('./popmachine.JPG'),
    require('./poolballsandcues.JPG'),
    // Add more image paths here
  ];

  return (
    <div>
      <h2 className='activities-header-text'>More To Do At The Q!</h2>
      <ImageGallery images={images} />
      {/* Add more content here */}
      <div className='textbackground location'>
      {/* <h3 className='homebodytext'>LOCATION</h3> */}
      {/* <hr className='horizontal-row'/> */}
      <p className='homebodytextp'>Multiple dart boards available</p>
      <p className='homebodytextp'>Axe throwing lanes - Coming soon!</p>
      </div>
    </div>
  );
}

export default Activities;
