import './App.css';
import React from 'react';
import {Route, Routes} from "react-router-dom"
import Header from './components/header/Header'
import Home from './components/home/Home';
import Nav from './components/nav/Nav';
import Footer from './components/footer/Footer';
import Rules from './components/rules/Rules';
import Activities from './components/activities/Activities';

function App() {
  return (
    <div className="App">
      <Header />
      <Nav />
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/home" element={<Home />}/>
        <Route path="/rules" element={<Rules />}/>
        <Route path="/activities" element={<Activities />}/>

      </Routes>
      <Footer />
    </div>
  );
}

export default App;
