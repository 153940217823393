import React from 'react'
import poolStick from './pool-stick-file.png'
import qhall from './theqhall.JPG'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from 'styled-components'

import {
  faArrowUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";

const FacebookButton = styled.a`
  color: #fff;
  text-decoration: none;
  font-family: 'Roboto Slab';
  padding: 10px 20px;
  border: 2px solid #fff;
  border-radius: 5px;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: #0047AB;
    border: 2px solid #0047AB;
    color: white;
  }
`;


function Home(props) {
  return (
    <div>
      
      <h2 className='homeheadertext section'>Northern Michigan's premier family-friendly destination for pool, billiards, and more!</h2>

      {/* <div className='homeimagealign'> */}
        <div className='qimgdiv'>
      <img className='qhallimg' src={qhall} alt="the Q view" />
        </div>
      <div className='textbackground location'>
      <h3 className='homebodytext'>LOCATION</h3>
      <hr className='horizontal-row'/>
      <p className='homebodytextp'>1050 S. Wisconsin Ave.<br/>
        Gaylord, MI 49735
      </p>
      {/* </div> */}

      </div>
      <img className='pool-stick1' src={poolStick} alt="pool cue" />

        <div className='textbackground'>
      <h3 className='homebodytext'>BUSINESS HOURS</h3>
      <hr className='horizontal-row'/>
      <p className='homebodytextp'>Monday - Friday: 2pm-11pm</p>
        <p className='homebodytextp'>Saturday: 11am-11pm</p>
       <p className='homebodytextp'>Sunday: 11am-8pm</p>
       </div>


      <img className='pool-stick2' src={poolStick} alt="pool cue" />

      <div className='textbackground'>
      <h3 className='homebodytext'>TABLE RATES</h3>
      <hr className='horizontal-row'/>
      <p className='homebodytextp'>$10.00 per hour on 8' tables.</p>
       <p className='homebodytextp'>$12.00 per hour on 9' tables.</p>
       <p className='homebodytextp'>*Flat rate for parties of 4 and under. $2 per additional player.</p>
       </div>

      <img className='pool-stick1' src={poolStick} alt="pool cue" />

      <div className='textbackground'>
      <h3 className='homebodytext'>EVENTS</h3>
      <hr className='horizontal-row'/>
      <p className='homebodytextp'>Tournaments and Leagues</p>
      <p className='homebodytextp'>8 ball, 9 ball, & 10 ball.</p>
      <p className='homebodytextp'>Frequent 9 ball break & runs.</p>
      </div>

      <img className='pool-stick2' src={poolStick} alt="pool cue" />

      <div className='textbackground'>
      <h3 className='homebodytext'>SOCIAL MEDIA</h3>
      <hr className='horizontal-row'/>
      <p className='homebodytextp'>Like us on Facebook for details on upcoming events, tournaments and more!</p>
      {/* <Button color="light"><a className='facebook-button' href="https://www.facebook.com/profile.php?id=61554122780995" target="_blank" rel="noopener noreferrer">The Q Billiards Facebook</a>
      </Button> */}
     <FontAwesomeIcon icon="fa-light fa-arrow-up-right-from-square" />
       <FacebookButton target='_blank' rel="noopener noreferrer" href="https://www.facebook.com/profile.php?id=61554122780995">
        The Q Billiards Facebook <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
      </FacebookButton>
      </div>

      <img className='pool-stick1' src={poolStick} alt="pool cue" />

      <div className='textbackground'>
      <h3 className='homebodytext'>CONTACT INFO</h3>
      <hr className='horizontal-row'/>
      <p className='homebodytextp'>Phone: 989-448-8556</p>
      <p className='homebodytextp'>Email: theqbilliards1050@gmail.com</p>
      </div>

      <img className='pool-stick2' src={poolStick} alt="pool cue" />
      {/* <h3 className='homebodytext'>GALLERY</h3> */}

      </div>
  )
}

export default Home