import React from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {navBackground} from './client/src/components/nav/mockup-photos-5nUNdLueQio-unsplash.jpg'
// import { useNavigate } from "react-router-dom";

import {
  faBullseye,
  faQ,
  faStore,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons";

function Nav(props) {
  // const navigate = useNavigate();

  // const handleMyConvosClick = () => {
  //   // Use navigate to redirect to /myconversations
  //   navigate('/myconversations');
  // };


  return (
    <div className="navbar">
      <NavLink to="/home" exact="true" activeclassname="active" >
        <FontAwesomeIcon icon={faQ} />
        <br />
        Home
      </NavLink>

      <NavLink to="/activities" activeclassname="active" >
        <FontAwesomeIcon icon={faBullseye} />


        <br />
        Activities
      </NavLink>

      <NavLink to="/rules" activeclassname="active">
        <FontAwesomeIcon icon={faHandshake} />
        <br />
        Rules
      </NavLink>

     
     <a href="https://www.cueandcase.com/" target="_blank" rel="noopener noreferrer">
     <FontAwesomeIcon icon={faStore} /><br/>
  Cue & Case
</a>

    </div>
  );
}

export default Nav