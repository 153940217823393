import React from 'react';
import CSIRules from './8ball page 1 csi.pdf';

function Rules(props) {
  return (
    <div>
        <h3 className='homeheadertext'>OFFICIAL RULES OF CUESPORTS INTERNATIONAL</h3>

        {/* Display PDF using iframe */}
        <iframe src={CSIRules} title="CSIRules" width="100%" height="600px" frameborder="0"></iframe>
        
    </div>
  );
}

export default Rules;
