// ImageGallery.js
import React, { useState } from 'react';
import './ImageGallery.css'; // Import CSS styles

const ImageGallery = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const goToNext = () => {
    setCurrentImageIndex((currentIndex) =>
      currentIndex === images.length - 1 ? 0 : currentIndex + 1
    );
  };

  const goToPrevious = () => {
    setCurrentImageIndex((currentIndex) =>
      currentIndex === 0 ? images.length - 1 : currentIndex - 1
    );
  };

  return (
    <div className="image-gallery-container">
      <div className="image-gallery-item">
        <img src={images[currentImageIndex]} alt={`Image ${currentImageIndex}`} />
      </div>
      <div className="image-gallery-buttons">
        <button onClick={goToPrevious}>&lt; Previous</button>
        <button onClick={goToNext}>Next &gt;</button>
      </div>
    </div>
  );
};

export default ImageGallery;
