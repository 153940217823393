import React from 'react';
import Logo from './Logo';

function Header() {
  return (
    <div className='header'>
      <Logo />
    </div>
  )
}

export default Header